import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { AppConfig } from '@/app-config';

export const init = () => {
  if (!AppConfig.sentry.enabled) {
    return;
  }

  Sentry.init({
    environment: AppConfig.env,
    dsn: AppConfig.sentry.dsn,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        enableInp: true,
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    beforeSend(event, hint) {
      const error = hint.originalException;

      // Exclude errors with messages starting with "NO_SENTRY"
      if (error instanceof Error && error.message.startsWith('NO_SENTRY')) {
        return null;
      }

      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: AppConfig.sentry.tracesSampleRate,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: AppConfig.sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: AppConfig.sentry.replaysOnErrorSampleRate,
    release: __APP_VERSION__,
  });
};
