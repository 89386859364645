import { createAppConfig } from '@newcross-tech/app-config-core';
import * as yup from 'yup';

export const AppConfig = createAppConfig({
  parse: (values) =>
    yup
      .object({
        env: yup.string().required(),
        hfhrService: yup.object({
          apiUrl: yup.string().required(),
        }),
        documentService: yup.object({
          apiUrl: yup.string().required(),
        }),
        keyCloak: yup
          .object({
            url: yup.string().required(),
            realm: yup.string().required(),
            clientId: yup.string().required(),
            resourceConfig: yup.object({
              hfhrService: yup.string().required(),
              documentService: yup.string().required(),
            }),
          })
          .required(),
        sentry: yup.object({
          enabled: yup.boolean().required(),
          dsn: yup.string().required(),
          tracesSampleRate: yup.number().required(),
          replaysSessionSampleRate: yup.number().required(),
          replaysOnErrorSampleRate: yup.number().required(),
        }),
        firebase: yup.object({
          enabled: yup.boolean().required(),
          apiKey: yup.string().required(),
          authDomain: yup.string().required(),
          projectId: yup.string().required(),
          storageBucket: yup.string().required(),
          messagingSenderId: yup.string().required(),
          appId: yup.string().required(),
          vapidKey: yup.string().required(),
        }),
        support: yup.object({
          email: yup.string().required(),
        }),
      })
      .required()
      .validateSync(values, { strict: true, recursive: true }),
});
